<template>
  <div class="pa-3">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-dialog
        v-model="showDetail"
        max-width="1400px"
        persistent
        transition="dialog-top-transition"
        @keydown.esc="close"
      >
        <v-card>
          <v-toolbar class="mb-4">
            <v-toolbar-title
              ><v-chip
                color="black"
                label
                text-color="white"
                style="font-size: 18px"
                >{{ formTitle }}</v-chip
              ></v-toolbar-title
            >
            <v-spacer></v-spacer>
            <v-btn
              text
              color="indigo"
              @click="save"
              v-if="selected_products.length > 0"
              :disabled="change == 2"
              class="mx-2"
            >
              Enregistrer
              <v-icon right dark> mdi-text-box-check-outline</v-icon>
            </v-btn>
            <v-btn color="red" @click.stop="close" text>
              Fermer <v-icon right dark> mdi-close</v-icon></v-btn
            >
          </v-toolbar>
          <v-progress-linear
            v-if="progress"
            indeterminate
            :size="30"
            :width="7"
            color="purple"
          ></v-progress-linear>

          <v-card-text>
            <v-container fluid>
              <v-row>
                <v-col cols="12" sm="3" md="3">
                  <v-text-field
                    autocomplete="off"
                    v-model="editedItem.no_of"
                    label="OF"
                    dense
                    :readonly="
                      editedItem.statut_id != '7' || editedItem.nbfiche > 0
                    "
                    @change="change++"
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="2" md="2">
                  <datepicker
                    label="Date"
                    v-model="editedItem.bt_date"
                    :clearable="false"
                    :date_min="editedItem.id < 0 ? today : null"
                    :edit="
                      editedItem.statut_id == '7' && editedItem.nbfiche == 0
                    "
                    @change="bt_change"
                    :key="cs"
                  ></datepicker>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-select
                    ref="machines"
                    :items="machines"
                    v-model="editedItem.machine_id"
                    item-text="label"
                    item-value="id"
                    label="Machine"
                    :readonly="
                      editedItem.statut_id != '7' || editedItem.nbfiche > 0
                    "
                    @change="bt_change"
                    :rules="[(v) => !!v || 'Machine obligatoire']"
                    dense
                  >
                    <template v-slot:selection="{ item }">
                      <!-- HTML that describe how select should render selected items -->
                      {{ item.code + "-" + item.label }}
                    </template>
                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="item.code + '-' + item.label"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="12" sm="1" md="1" v-if="jobs_order.length > 0">
                  <v-select
                    ref="orders"
                    :items="jobs_order"
                    v-model="editedItem.job_order"
                    label="Priorité"
                    :readonly="
                      editedItem.statut_id != '7' || editedItem.nbfiche > 0
                    "
                    dense
                  >
                  </v-select>
                </v-col>
                <v-col cols="12" sm="2" md="2">
                  <v-select
                    ref="status"
                    :items="[
                      { id: '7', label: 'Att. Execution' },
                      { id: '9', label: 'Soldé Man.' },
                    ]"
                    item-value="id"
                    item-text="label"
                    v-model="editedItem.statut_id"
                    :readonly="!valider"
                    label="Statut"
                    dense
                  >
                  </v-select>
                </v-col>
                <v-col cols="12" sm="6" md="12">
                  <v-card elevation="4">
                    <v-card-title>
                      <v-row>
                        <v-col cols="12" sm="3" md="3">
                          <v-autocomplete
                            class="mt-4"
                            ref="ofs"
                            :items="clients"
                            v-model="editedItem.tier_id"
                            item-text="tier_name"
                            :item-value="'tier_id'"
                            label="Client"
                            :readonly="
                              editedItem.statut_id != '7' ||
                              selected_products.length > 0
                            "
                            :rules="[(v) => !!v || 'Client obligatoire']"
                            dense
                            @change="set_products"
                            v-if="
                              editedItem.statut_id == '7' &&
                              editedItem.nbfiche == 0
                            "
                          >
                          </v-autocomplete>
                          <span v-else>
                            Client : {{ editedItem.tier_name }}
                          </span>
                        </v-col>

                        <v-col cols="12" sm="6" md="6">
                          <v-autocomplete
                            class="mt-4"
                            ref="ofs"
                            :items="products"
                            v-model="cmd_det_id"
                            item-value="id"
                            label="Produit"
                            :filter="customFilter"
                            :readonly="editedItem.statut_id != '7'"
                            @change="product_change"
                            dense
                            v-if="
                              editedItem.statut_id == '7' &&
                              editedItem.nbfiche == 0
                            "
                          >
                            <template v-slot:selection="{ item }">
                              <!-- HTML that describe how select should render selected items -->
                              {{ item.code + "-" + item.label }}
                            </template>
                            <template v-slot:item="{ item }">
                              <v-list-item-content>
                                <v-list-item-title
                                  v-html="item.code + '-' + item.label"
                                ></v-list-item-title>

                                <v-list-item-subtitle>
                                  <v-row>
                                    <v-col cols="12" sm="3" md="3">
                                      <span>
                                        {{ "N° Cmd : " + item.nocmd }}
                                      </span>
                                    </v-col>
                                    <v-col cols="12" sm="3" md="3">
                                      <span>
                                        {{
                                          "Qte Commandée : " +
                                          numberWithSpace(
                                            item.qte ? item.qte.toFixed(2) : 0
                                          ) +
                                          " " +
                                          (item.unit ? item.unit : "")
                                        }}
                                      </span>
                                    </v-col>
                                    <v-col cols="12" sm="3" md="3">
                                      <span>
                                        {{
                                          "Qte fabriquée : " +
                                          numberWithSpace(
                                            item.qte_fab
                                              ? item.qte_fab.toFixed(2)
                                              : 0
                                          ) +
                                          " " +
                                          (item.unit ? item.unit : "")
                                        }}
                                      </span>
                                    </v-col>
                                    <v-col cols="12" sm="3" md="3">
                                      <v-progress-linear
                                        :value="item.prc_fab"
                                        :color="
                                          item.prc_fab > 100
                                            ? 'orange'
                                            : 'light-green darken-2'
                                        "
                                        height="25"
                                        rounded
                                      >
                                        <template v-slot:default>
                                          {{ item.prc_fab + "% " }}
                                        </template>
                                      </v-progress-linear>
                                    </v-col>
                                  </v-row>
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </template>
                          </v-autocomplete>
                        </v-col>

                        <v-col
                          cols="12"
                          sm="12"
                          md="12"
                          lg="12"
                          v-if="selected_products.length > 0"
                        >
                          <v-sheet rounded="lg" color="grey darken-1">
                            <v-chip-group
                              active-class="primary--text"
                              column
                              class="pa-4 my-1"
                            >
                              <v-chip
                                color="green"
                                v-for="product in selected_products"
                                :key="product.id"
                                :close="
                                  editedItem.statut_id == '7' &&
                                  editedItem.nbfiche == 0
                                "
                                @click:close="delchips(product)"
                              >
                                {{ product.code + "-" + product.label }}
                              </v-chip>
                            </v-chip-group>
                          </v-sheet>
                        </v-col>
                      </v-row>
                    </v-card-title>
                    <v-divider></v-divider>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-alert v-model="alert" :type="type_alert" dismissible>{{
              message
            }}</v-alert>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>

    <confirmdialog ref="confirm" />
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
  </div>
</template>

<script>
//import CREATE_BT from "../graphql/Production/CREATE_BT.gql";
import GET_JOB_ORDER from "../graphql/Production/GET_JOB_ORDER.gql";
import UPSERT_BT from "../graphql/Production/UPSERT_BT.gql";
export default {
  components: {
    confirmdialog: () => import("./ConfirmDialog.vue"),
    datepicker: () => import("./DatePicker.vue"),
  },
  name: "btprod",
  props: {
    item: Object,
    items: Array,
    cmds_details: Array,
    machines: Array,
    showForm: Boolean,
    readonly: Boolean,
    editer: Boolean,
    valider: Boolean,
    solder: Boolean,
  },
  data: () => ({
    glist: false,
    imp: false,
    title: "Liste Produit",
    listload: false,
    list: false,
    progress: false,
    isListClose: true,
    valid: true,
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
    defaultItem: {
      id: -1,
      bt_date: null,
      statut_name: "Brouillon",
      statut_id: "7",
      nbfiche: 0,
    },
    cs: 1220,
    bkey: 1320,
    unit_category: null,
    unit: null,
    editedItem: {},
    commandes: [],
    products: [],
    selected_products: [],
    jobs_order: [],
    decimal: 2,
    qte: 0,
    tol: 0,
    tol_rcpt: 0,
    prc_fab: 0,
    frml: 600,
    kl: 900,
    qte_max: 0,
    today: null,
    machine: {},
    alert: false,
    type_alert: "info",
    message: "",
    cmd_det_id: null,
    change: 0,
  }),
  watch: {},
  computed: {
    clients() {
      return this.cmds_details ? this.cmds_details : [];
    },

    showDetail() {
      return this.showForm;
    },
    formTitle() {
      if (this.item)
        return !this.item.no_bt ? "Nouveau Bon de Travail " : this.item.no_bt;
      else return "";
    },
  },

  created() {},
  mounted() {
    this.today = this.$store.state.today;
    if (this.item) {
      if (this.item.id > 0) {
        this.editedItem = Object.assign({}, this.item);
        this.set_products();
        this.selected_products = this.editedItem.details;
      } else {
        this.defaultItem.bt_date = this.$store.state.today;

        this.editedItem = Object.assign({}, this.defaultItem);
        if (this.machines.length == 1) {
          this.editedItem.machine_id = this.machines[0].id;
        }
      }
      this.cs++;
    }
  },

  methods: {
    delchips(item) {
      let i = this.selected_products.findIndex(
        (elm) => elm.cmd_det_id == item.cmd_det_id
      );
      if (i >= 0) {
        this.selected_products[i].id = item.cmd_det_id;
        this.products.unshift(this.selected_products[i]);
        this.selected_products.splice(i, 1);
      }
      this.change++;
    },
    product_change(item) {
      let i = this.products.findIndex((elm) => elm.id == item);
      if (i >= 0) {
        this.products[i].cmd_det_id = item;
        this.editedItem.cmd_id = this.products[i].cmd_id;
        this.selected_products.push(this.products[i]);
        this.products.splice(i, 1);
        this.set_products();
      }
      this.cmd_det_id = null;
      this.bkey++;
      this.change++;
    },
    set_products() {
      this.cmd_det_id = [];
      this.products = this.cmds_details
        ? this.cmds_details.filter(
            (elm) =>
              elm.tier_id == this.editedItem.tier_id &&
              !this.selected_products
                .map((elm) => elm.id)
                .includes(elm.cmd_det_id) &&
              (this.editedItem.cmd_id
                ? elm.cmd_id == this.editedItem.cmd_id
                : true)
          )
        : [];
      this.change++;
    },
    async bt_change() {
      if (this.editedItem.machine_id && this.editedItem.bt_date) {
        let r = await this.requette(GET_JOB_ORDER, {
          machine_id: this.editedItem.machine_id,
          bt_date: this.editedItem.bt_date,
        });
        if (r) {
          if (this.editedItem.id < 0) this.editedItem.job_order = r.GetJobOrder;
          this.jobs_order = [];
          for (let index = 1; index <= this.editedItem.job_order; index++) {
            this.jobs_order.push(index);
          }
        }
      }
      this.change++;
    },

    numberWithSpace: function (x) {
      return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ");
    },
    datefr(date) {
      var result = new Date(date);
      return result.toLocaleDateString("fr-FR", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },
    async maj(query, v, refresh) {
      let r;
      this.progress = true;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then((data) => {
          if (refresh) {
            this.$store.dispatch("Changed", true);
            this.$emit("change");
          }
          r = data.data;
          this.progress = false;
        })
        .catch((error) => {
          this.loading = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    customFilter(item, queryText) {
      let textlist = [];
      [
        "ref",
        "ref_ext",
        "code",
        "label",
        "box",
        "rack",
        "tier_name",
        "nocmd",
        "date_cmd",
      ].forEach((element) => {
        const text1 = item[element] ? item[element].toLowerCase() : "";
        textlist.push(text1);
      });

      const searchText = queryText.toLowerCase();
      const index = textlist.findIndex((element) => {
        if (element.includes(searchText)) {
          return true;
        }
      });
      return index !== -1;
    },

    async requette(query, v) {
      this.progress = true;

      let r = [];

      await this.$apollo
        .query({
          query: query,
          variables: v,
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          r = data.data;
          this.progress = false;
        })
        .catch((error) => {
          this.loading = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },

    async close() {
      let ok = true;
      if (this.change > 2) {
        ok = false;
        if (
          await this.$refs.confirm.open(
            "Confirmation",
            "Modification encours!! - Etês-vous sûr de vouloir quitter?"
          )
        ) {
          ok = true;
        }
      }
      if (ok) this.$emit("close", this.editedItem);
    },

    update_product() {},
    async save() {
      this.alert = false;
      if (this.$refs.form.validate()) {
        let ok = true;

        if (ok) {
          let v = {
            bt: [
              {
                id: this.editedItem.id > 0 ? this.editedItem.id : null,
                bt_date: this.editedItem.bt_date,
                no_of: this.editedItem.no_of,
                qte: this.editedItem.qte,
                machine_id: this.editedItem.machine_id,
                job_order: this.editedItem.job_order,
                statut_id: this.editedItem.statut_id,
                outputs: this.selected_products.map((elm) => elm.cmd_det_id),
              },
            ],
          };
          let r = await this.maj(UPSERT_BT, v);
          if (r) {
            if (!this.editedItem.id) this.editedItem.id = r.UpsertBT;
            this.$store.dispatch("Changed", true);
          }

          this.change = 2;
          this.$emit("refresh", this.editedItem);
          this.$emit("close", this.editedItem);
        }
      }
    },
  },
};
</script>
